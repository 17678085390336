import store from '../../store'

export default [
    {
        value: "Dossier",
        text: store.getters.translate("dossiers"),
        relation: "dossiers",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "stipel_added_certificate",
                text: store.getters.translate("stipel_added_certificate"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "certifiable",
                text: store.getters.translate("certifiable"),
            },
            {
                value: "dossier_closed",
                text: store.getters.translate("dossier_closed"),
            },
            {
                value: "valid_certificate_issued_2",
                text: store.getters.translate("valid_certificate_issued"),
            },
        ],
    },
    {
        value: "Certificate",
        text: store.getters.translate("certificates"),
        relation: "certificates",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "valid_certificate_issued",
                text: store.getters.translate("valid_certificate_issued"),
            },

        ],
    },
    {
        value: "Exam",
        text: store.getters.translate("exams"),
        relation: "exams",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "exam_result_set",
                text: store.getters.translate("exam_result_set"),
            },
            {
                value: "exam_got_new_candidate",
                text: store.getters.translate("exam_got_new_candidate"),
            },
            {
                value: "candidate_deleted_from_exam",
                text: store.getters.translate("candidate_deleted_from_exam"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
        ],
    },
    {
        value: "Session",
        text: store.getters.translate("sessions"),
        relation: "sessions",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },

        ],
    },
    {
        value: "Candidate",
        text: store.getters.translate("candidates"),
        relation: "candidates",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "portal_documents_added",
                text: store.getters.translate("portal_documents_added"),
            },
            {
                value: "co_signed",
                text: store.getters.translate("co_signed"),
            },
            {
                value: "examination_form_filled",
                text: store.getters.translate("examination_form_filled"),
            },
            {
                value: "candidate_added_to_exam",
                text: store.getters.translate("candidate_added_to_exam"),
            },
            {
                value: "candidate_registered_for_exam",
                text: store.getters.translate("candidate_registered_for_exam"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "candidate_has_logged_in",
                text: store.getters.translate("candidate_has_logged_in"),
            },
        ],
    },
    {
        value: "Assessor",
        text: store.getters.translate("assessors"),
        relation: "assessors",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "assessor_has_logged_in",
                text: store.getters.translate("assessor_has_logged_in"),
            },

        ],
    },
    {
        value: "Contact",
        text: store.getters.translate("contacts"),
        relation: "contacts",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "contact_has_logged_in",
                text: store.getters.translate("contact_has_logged_in"),
            },

        ],
    },
    {
        value: "Employee",
        text: store.getters.translate("employees"),
        relation: "employees",
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
            {
                value: "employee_has_logged_in",
                text: store.getters.translate("employee_has_logged_in"),
            },

        ],
    },
    {
        value: "Scheme",
        text: store.getters.translate("schemes"),
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
        ],
    },
    {
        value: "Note",
        text: store.getters.translate("notes"),
        children: [
            {
                value: "created",
                text: store.getters.translate("created"),
            },
            {
                value: "updated",
                text: store.getters.translate("updated"),
            },
            {
                value: "deleted",
                text: store.getters.translate("deleted"),
            },
            {
                value: "scheduler",
                text: store.getters.translate("scheduler"),
            },
        ],
    },
    // {
    //   value: "Location",
    //   text: store.getters.translate("locations"),
    //   children: [
    //     {
    //       value: "created",
    //       text: store.getters.translate("created"),
    //     },
    //     {
    //       value: "updated",
    //       text: store.getters.translate("updated"),
    //     },
    //     {
    //       value: "deleted",
    //       text: store.getters.translate("deleted"),
    //     },
    //   ],
    // },
    // {
    //   value: "Room",
    //   text: store.getters.translate("rooms"),
    //   children: [
    //     {
    //       value: "created",
    //       text: store.getters.translate("created"),
    //     },
    //     {
    //       value: "updated",
    //       text: store.getters.translate("updated"),
    //     },
    //     {
    //       value: "deleted",
    //       text: store.getters.translate("deleted"),
    //     },
    //   ],
    // },
    // {
    //   value: "Schemesession",
    //   text: store.getters.translate("schemesessions"),
    //   children: [
    //     {
    //       value: "created",
    //       text: store.getters.translate("created"),
    //     },
    //     {
    //       value: "updated",
    //       text: store.getters.translate("updated"),
    //     },
    //     {
    //       value: "deleted",
    //       text: store.getters.translate("deleted"),
    //     },
    //   ],
    // },
    // {
    //   value: "Company",
    //   text: store.getters.translate("companies"),
    //   children: [
    //     {
    //       value: "created",
    //       text: store.getters.translate("created"),
    //     },
    //     {
    //       value: "updated",
    //       text: store.getters.translate("updated"),
    //     },
    //     {
    //       value: "deleted",
    //       text: store.getters.translate("deleted"),
    //     },
    //   ],
    // },
]